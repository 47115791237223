<template>
  <div class="mp4_replay_course_page_container">
    <authentication>
      <live-page-nav :course-name="videoInfo.name"></live-page-nav>
    </authentication>
    <video
      class="video"
      id="video"
      ref="video"
      controlsList="nodownload"
      disablePictureInPicture
      autoplay
      @contextmenu.prevent.stop
    >
      <source :src="videoInfo.videoUrl" />
    </video>
    <div class="playerControlBox">
      <el-slider
        style="width: 100%"
        v-model="playProgress"
        :show-tooltip="false"
        :step="0.01"
        @change="changeProgress"
      ></el-slider>
      <div class="playerControl">
        <div class="playerControl-left">
          <img
            @click="onPlay"
            class="play"
            :src="!isPlay ? play : pause"
            alt=""
          />
          <img @click="onStop" class="play" :src="stop" alt="" />
          <div class="time">{{ curTime }}</div>
          <div class="gang">/</div>
          <div class="time">{{ totalTime }}</div>
        </div>
        <div class="playerControl-right">
          <img
            class="loudspeaker"
            @click="closeVolume"
            :src="volume === 0 ? silence : loudspeaker"
          />
          <el-slider
            style="width: 120px"
            v-model="volume"
            :show-tooltip="false"
            @change="changeVolume"
          ></el-slider>
          <span class="volume">{{ volume }}%</span>
          <div class="speed">
            <span @click="isSpeed = !isSpeed">{{ speedText }}</span>
            <hcp-popover
              @change="changeSpeed"
              :list="speedList"
              v-model:visible="isSpeed"
            />
          </div>
          <img
            @click="onFull"
            :src="!isFull ? fullScreen : noFullscreen"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatSecond } from "@/utils/common";
import Authentication from "@/components/Authentication/index.vue";
import { localStorageUtils } from "@/utils/storage";
import HcpPopover from "../replayLive/components/HcpPopover.vue";
import LivePageNav from "../../components/LivePageNav.vue";
import { logReplayCourse } from "@/services/course.js";
export default {
  components: { LivePageNav, HcpPopover, Authentication },
  data() {
    return {
      videoInfo: {
        name: "",
        videoUrl: "",
      },
      loudspeaker: require("@/assets/img/loudspeaker.png"),
      silence: require("@/assets/img/silence.png"),
      fullScreen: require("@/assets/img/fullScreen.png"),
      noFullscreen: require("@/assets/img/noFullscreen.png"),
      play: require("@/assets/img/play.png"),
      pause: require("@/assets/img/pause.png"),
      stop: require("@/assets/img/stop.png"),
      playProgress: 0,
      curTime: "",
      totalTime: "",
      volume: 60,
      speedText: "倍速",
      isPlay: false,
      controlPlay: true,
      isSpeed: false,
      isFull: false,
      speedList: [
        {
          label: "正常播放",
          value: 1,
        },
        {
          label: "0.75x",
          value: 0.75,
        },
        {
          label: "1.2x",
          value: 1.2,
        },
        {
          label: "1.5x",
          value: 1.5,
        },
        {
          label: "1.8x",
          value: 1.8,
        },
        {
          label: "2x",
          value: 2,
        },
      ],
    };
  },

  created() {
    if (this.$route.query.time) {
      this.videoInfo = localStorageUtils.getItem("video") || {};
    } else {
      const time = new Date().getTime();
      if (this.$route.query.videoUrl) {
        this.videoInfo = {
          videoUrl: this.$route.query.videoUrl,
          name: this.$route.query.name,
        };
        localStorageUtils.setItem("video", this.videoInfo);
        const params = { time, debug: true };
        if (this.$route.query.id) {
          params.id = this.$route.query.id;
        }
        this.$router.replace({ path: this.$route.path, query: params });
      }
    }
  },
  mounted() {
     if (this.$route.query.id) {
      this.createLog(this.$route.query.id);
    }

    const video = document.getElementById("video");
    video.addEventListener("timeupdate", (e) => {
      if (this.controlPlay) {
        this.isPlay = true;
      } else {
        this.isPlay = false;
      }
      const { duration, currentTime } = e.target || {};
      this.totalTime = formatSecond(Math.floor(duration));
      this.curTime = formatSecond(Math.floor(currentTime));
      this.playProgress = parseFloat(
        ((currentTime / duration) * 100).toFixed(2)
      );
    });
  },
  methods: {
    async createLog(id) {
      await logReplayCourse({ courseDetailId: id });
    },
    saveVideoInfo(key) {},
    changeProgress(val) {
      const curTime = Math.floor(this.$refs.video.duration) * (val / 100);
      this.$refs.video.currentTime = curTime;
    },
    onPlay() {
      if (this.isPlay) {
        this.$refs.video.pause();
      } else {
        this.$refs.video.play();
      }
      this.isPlay = !this.isPlay;
      this.controlPlay = this.isPlay;
    },
    onStop() {
      this.$refs.video.currentTime = 0;
      this.isPlay = false;
      this.$refs.video.pause();
      this.controlPlay = this.isPlay;
    },
    closeVolume() {
      const volume = sessionStorage.getItem("volume") || 100;
      if (this.volume > 0) {
        this.volume = 0;
        this.$refs.video.volume = 0;
      } else {
        this.volume = typeof volume === "string" ? Number(volume) : volume;
        this.$refs.video.volume = volume / 100;
      }
    },
    changeVolume(val) {
      this.$refs.video.volume = val / 100;
      sessionStorage.setItem("volume", val);
    },
    changeSpeed(item) {
      this.isSpeed = false;
      if (item.value === 1) {
        this.speedText = "倍速";
      } else {
        this.speedText = item.label;
      }
      this.$refs.video.playbackRate = item.value;
    },
    onFull() {
      this.$refs.video.webkitRequestFullScreen();
    },
  },
};
</script>

<style lang="scss">
.playerControlBox > .el-slider .el-slider__runway {
  margin: -2px 0 0;
  height: 2px;
  .el-slider__bar {
    height: 2px;
  }
  .el-slider__button-wrapper {
    top: -16px;
  }
  .el-slider__button {
    background-color: #37f;
    width: 8px;
    height: 8px;
    border: 0;
  }
}
</style>
<style lang="scss" scoped>
.mp4_replay_course_page_container {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  .video {
    flex: 1;
    height: calc(100vh - 112px);
  }
  .playerControlBox {
    width: 100%;
    background-color: #fff;
    height: 52px;
    .playerControl {
      padding: 0 15px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        color: #909090;
        font-size: 14px;
        .play {
          margin-right: 26px;
          cursor: pointer;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        .loudspeaker {
          margin: 0 15px;
        }
        .volume {
          color: #909090;
          margin-left: 15px;
        }
        .speed {
          height: 20px;
          line-height: 20px;
          color: #fff;
          font-size: 14px;
          padding: 0 6px;
          cursor: pointer;
          background-color: #37f;
          border-radius: 6px;
          margin: 0 30px 0 20px;
          position: relative;
        }
        img {
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
